import React, { useContext } from "react";
import styles from "./index.module.css";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import Button from "../../components/atoms/Button";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";
import { useNavigate } from "react-router-dom";
import { createAgreement } from "../../services/sanctions";
let Digio: any;

function Agreement() {
  const applicationId = sessionStorage.getItem("appId")?? ""
  const userId = sessionStorage.getItem("userId")?? ""
  const mobileNumber = sessionStorage.getItem("mobile")?? ""
  const navigate = useNavigate();

  const fetchJsFromCDN = (src: any, externals: string[]) => {
    externals = !externals ? (externals = []) : externals;
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.setAttribute("src", src);
      script.addEventListener("load", () => {
        resolve(
          externals.map((key: any) => {
            const ext = window[key];
            typeof ext === "undefined" &&
              console.warn(`No external named '${key}' in window`);
            return ext;
          })
        );
      });
      script.addEventListener("error", reject);
      document.body.appendChild(script);
    });
  };

  function digilockerHandler() {
    fetchJsFromCDN("https://app.digio.in/sdk/v10/digio.js", ["Digio"]).then(
      (digio: any) => {
        createAgreement(applicationId, userId)
          .then((result) => {
            console.log(result);

            const { email, id, tokenId } = result.data;

            Digio = digio[0];
            let d = new Digio({
              environment: "production",
              logo: "yourlogourl",
              theme: {
                primaryColor: "#234FDA",
                secondaryColor: "#234FDA",
              },
              digioDocumentId: id,
              digioUserIdentifier: email,
              is_iframe: true,
              callback: (_digio: any) => {
                if (_digio.error_code === "CANCELLED") {
                  navigate("/sanctions/mandate");
                  return;
                }
                if (_digio.error_code !== undefined) {
                  navigate("/sanctions/mandate");
                  throw new Error(_digio.message);
                }

                navigate("/sanctions/mandate");
              },
            });

            d.init();
            d.submit(id, email, tokenId);
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <Button
            onPress={() => {
              digilockerHandler();
            }}
            text={"Sign Agreement"}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Agreement;
