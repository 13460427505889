import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/atoms/Button";
import styles from "./index.module.css";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/atoms/InputText";
import { Box, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Label from "../../components/atoms/Label";
import ArrowLeft from "../../images/icons/arrow_left.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import FeemonkText from "../../components/atoms/FeemonkText";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";
import { createApplication } from "../../services/application";
import axios from "axios";
import moment from "moment";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils/keys";
import { ApplicantStage } from "../../utils/applicationStage";

function EnterPan() {
  const navigate = useNavigate();
  const { data } = useParams();
  const [userData, setUserData] = useState<{
    applicationId: string;
    userId: string;
    email: string;
    panId: string;
    dateOfBirth: string;
    instituteId: string;
    firstName: string;
    lastName: string;
    mobile: string;
    course: string;
    fees: number;
    studentName: string;
    instituteName: string;
    redirectUrl: string;
    channelId: number;
  }>({
    applicationId: "",
    userId: "",
    instituteId: "",
    firstName: "",
    lastName: "",
    panId: "",
    dateOfBirth: "",
    email: "",
    mobile: "",
    course: "",
    fees: 0,
    studentName: "",
    instituteName: "",
    redirectUrl: "",
    channelId: 0,
  });

  const [pan, setPan] = useState(
    process.env.NODE_ENV === "development" ? "" : userData.panId
  );
  const [dob, setDob] = useState(
    process.env.NODE_ENV === "development" ? "" : userData.dateOfBirth
  );

  const { updatePanProData, saveIds } = useContext(
    DataContext
  ) as DataContextType;
  // const [panDetails, setPanDetails] = useState({});

  const [position, setPosition] = useState<{
    latitude: number | null;
    longitude: number | null;
  }>({ latitude: null, longitude: null });

  const [error, setError] = useState("");

  useEffect(() => {
    if (data) {
      try {
        sessionStorage.setItem("data",data)
        setUserData(JSON.parse(atob(data || "")));
      } catch (error) {}
    }
  }, [data]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${userData.applicationId}`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res?.data?.stage) {
          switch (res?.data?.stage) {
            case ApplicantStage.PanVerification:
              navigate("/address", {
                state: { data: userData },
              });
              break;
            case ApplicantStage.EmploymentDetails:
              navigate("/work-details", { state: { data: userData } });
              break;
            case ApplicantStage.AddressDetails:
              navigate("/address", { state: { data: userData } });
              break;
            case ApplicantStage.BankStatement:
              navigate("/parking-page", { state: { data: userData } });
              break;
            case ApplicantStage.AwaitingApproval:
              navigate("/parking-page", { state: { data: userData } });
              break;
            case ApplicantStage.CoapplicantRequired:
              navigate("/parking-page", { state: { data: userData } });
              break;
            case ApplicantStage.AwaitingCoapplicantConsent:
              navigate("/parking-page", { state: { data: userData } });
              break;
            case ApplicantStage.Approved:
              navigate("/select-emi", { state: { data: userData } });
              break;
            case ApplicantStage.SanctionTermsAccepted:
              navigate("/sanctions", { state: { data: userData } });
              break;
            case ApplicantStage.Digilocker:
              navigate("/sanctions", { state: { data: userData } });
              break;
            case ApplicantStage.Selfie:
              navigate("/sanctions", { state: { data: userData } });
              break;
            case ApplicantStage.Agreement:
              navigate("/sanctions", { state: { data: userData } });
              break;
            case ApplicantStage.BankAccountVerified:
              navigate("/sanctions", { state: { data: userData } });
              break;
            case ApplicantStage.Mandate:
              navigate("/disbursed-page", { state: { data: userData } });
              break;
            case ApplicantStage.PFCollections:
              navigate("/pf-collection", { state: { data: userData } });
              break;
            case ApplicantStage.ProcessComplete:
              navigate("/disbursed-page", { state: { data: userData } });
              break;
            case ApplicantStage.Disbursed:
              navigate("/disbursed-page", { state: { data: userData } });
              break;
            case ApplicantStage.Rejected:
              navigate("/rejected-page", { state: { data: userData } });
              break;
            default:
              navigate(`/${atob(JSON.stringify(userData))}`);
              break;
          }
        }
      })
      .catch((err) => console.log(err));
  }, [userData.applicationId, navigate, userData]);

  const [openedModal, setOpened] = useState<boolean>(false); // Define open state variable

  const [checked, setChecked] = useState(false);

  // const handleOpened = (event: any) => {
  //   // if (event === true) {
  //   // }
  // };

  const handleCheck = (event: any) => {
    setChecked(event);
  };

  const handleCloseConsent = () => setOpened(false);

  const [link, setLink] = useState("");

  // const handleStartSession = (value: any) => {
  //   console.log("exe");
  //   const randomGen =
  //     Date.now().toString(36) + Math.random().toString(36).substr(2);

  //   (window as any)
  //     .getBureauSession(
  //       process.env.REACT_APP_CONSENT_KEY,
  //       randomGen,
  //       value.firstName,
  //       "",
  //       value.lastName,
  //       value.mobile
  //     )
  //     .then((res: any) => {
  //       console.log(res);

  //       setLink(res);
  //     });
  // };

  // const handleLoadSession = async () => {
  //   const result = await (window as any).startBureauSession();
  //   if (result) {
  //     switch (result.status) {
  //       case "SUCCESS":
  //         axios
  //           .get(
  //             `${process.env.REACT_APP_DASHBOARD_URL}/eligibility?phone=${userData.mobile}`
  //           )
  //           .then((res) => {
  //             const qecBody = {
  //               applicationId: userData.applicationId,
  //               userId: userData.userId,
  //               instituteId: userData.instituteId,
  //               studentName: userData.firstName,
  //               applicantName: userData.firstName + " " + userData.lastName,
  //               panId: userData.panId,
  //               dob: moment(userData.dateOfBirth).format("DD/MM/YYYY"),
  //               phone: userData.mobile,
  //               status: "Created",
  //               eligibility: res?.data?.data?.status,
  //             };

  //             axios
  //               .post(
  //                 `${process.env.REACT_APP_DASHBOARD_URL}rules/create/eligibility`,
  //                 qecBody
  //               )
  //               .then((result: any) => {})
  //               .catch((err) => {
  //                 console.log(err);
  //               });
  //           });
  //         // const headers = {
  //         //   'Authorization': `Bearer ${user}`,
  //         //   'Content-Type': 'application/json',
  //         // };
  //         // console.log(panProDetails)
  //         // const data = {
  //         //   mobile: decode?.mobile,
  //         //   firstName: panProDetails ? panProDetails?.user_full_name_split[0]?.trim() : cuserData?.fullName?.split(" ")[1],
  //         //   lastName: panProDetails ? panProDetails?.user_full_name_split[2]?.trim() : ckycData?.fullName?.split(" ")[2],
  //         //   instituteName: instituteName,
  //         //   studentName: studentName,
  //         //   dateOfBirth: dob,
  //         //   courseName: courseName,
  //         //   courseFees: courseFee,
  //         //   gender: panProDetails ? (panProDetails?.user_gender === "M" ? "Male" : "Female") : (ckycData?.gender === "M" ? "Male" : "Female"),
  //         //   panId: panProDetails ? panProDetails?.pan_number : ckycData?.panNumber,
  //         //   aadhaarId: panProDetails ? panProDetails?.masked_aadhaar : ckycData?.indentityList?.find(item => item.name === "E-KYC Authentication")?.id,
  //         //   email: panProDetails ? panProDetails?.user_email || applicantEmail : ckycData?.email,
  //         //   currentAddress: panProDetails && panProDetails?.user_address?.full ? panProDetails?.user_address?.full : ckycData?.currentAddress,
  //         //   currentCity: panProDetails && panProDetails?.user_address?.city ? panProDetails?.user_address?.city : ckycData?.currentCity,
  //         //   currentState: panProDetails && panProDetails?.user_address?.state ? panProDetails?.user_address?.state : ckycData?.currentState,
  //         //   currentPincode: panProDetails && panProDetails?.user_address?.zip ? panProDetails?.user_address?.zip : ckycData?.currentPincode,
  //         //   panImage: " ",
  //         //   aadhaarFrontImage: " ",
  //         //   aadhaarBackImage: " ",
  //         //   isCoapplicant: false,
  //         //   relatedTo: " ",
  //         //   employmentType: " ",
  //         //   employerName: " ",
  //         //   salary: " ",
  //         //   incomePerMonth: " ",
  //         //   typeOfBusiness: " ",
  //         //   salesperson: " ",
  //         //   loanTenure: " ",
  //         //   ocrId: "",
  //         //   channel: 4
  //         // };

  //         // handleLocationClick();

  //         // // Simulate the API call with setTimeout
  //         // setTimeout(() => {
  //         //   const userId = "dummyUserId"; // Simulated user ID

  //         //   if (userId) {
  //         //     const data2 = {
  //         //       userId,
  //         //       latitude: location.latitude,
  //         //       longitude: location.longitude,
  //         //     };

  //         //     // Simulate the second API call
  //         //     setTimeout(() => {
  //         //       console.log("Simulated second API call success");

  //         //       setToggleConsent(false);
  //         //       setTimeout(() => {
  //         //         console.log(data)
  //         //         navigate("/loan-steps-start", { state: { data, data2 } });
  //         //       }, 500);
  //         //     }, 500); // Simulate delay for the second API call
  //         //   }
  //         // }, 500); // Simulate delay for the first API call

  //         break;

  //       case "EXIT":
  //         alert("Retry Submit");
  //         handleCloseConsent();
  //         break;

  //       case "ERROR":
  //         alert("Error Please Try Later");
  //         handleCloseConsent();
  //         break;

  //       default:
  //         alert("Contact our team for assistance");
  //         break;
  //     }
  //   }
  // };

  const getPanPro = () => {
    console.log("Clicked");
    // setPanDetails(PANzoopData);

    // TODO: Send longitude and latitude to the backend from here.

    // Latitude: {position.latitude}, Longitude: {position.longitude}

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log("Hello");
        console.log(userData);

        createApplication({
          mobile: userData?.mobile,
          panId: pan,
          dob,
          // dob:
          //   dob.split("-")[0].length === 4
          //     ? dob.split("-").reverse().join("-")
          //     : dob,
          courseFees: userData?.fees,
          instituteName: userData?.instituteName,
          email: userData?.email,
          studentName: userData?.studentName,
          courseName: userData?.course,
          channelId: 5,
          latitude: position?.coords?.latitude,
          longitude: position?.coords?.longitude,
          userId: userData?.userId,
          applicationId: userData?.applicationId,
        })
          .then((result) => {
            console.log("IMP", result);

            if (result.message !== "Successful") {
              console.log("Error", result.message);

              setError(result.data || result.message);
              return;
            }

            // updatePanProData(PANzoopData);
            // updatePanProData(result.data);
            saveIds(
              userData?.applicationId,
              userData?.userId,
              userData?.fees?.toString(),
              userData?.mobile?.toString(),
              userData?.redirectUrl
            );
            // navigate("/work-details");
            navigate("/address", { state: { data: userData } });
          })
          .catch((err) => {
            console.log(err);
            setError("PAN not verified");
          });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
      alert("Please enable location services to proceed");
    }
  };

  return (
    <div className={styles.body}>
      {error && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
          onClick={() => setError("")}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "2rem 5rem",
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(0,0,0,0.3)",
              margin: "0 1rem",
            }}
            onClick={() => setError("")}
          >
            <h1
              style={{
                fontFamily: "Outfit",
                textAlign: "center",
              }}
            >
              Error: {error}
            </h1>
          </div>
        </div>
      )}
      {/* <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openedModal}
          keepMounted
          closeAfterTransition
        >
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="center"
              gap="40%"
              alignItems="center"
              style={{
                width: "371px",
                backgroundColor: "white",
                padding: "10px",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                OTP Consent
              </Typography>
              <IconButton
                style={{ border: "2px solid #DbDbDb", width: "40px" }}
                aria-label="remove"
                size="small"
                onClick={handleCloseConsent}
              >
                <ClearIcon />
              </IconButton>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              <iframe
                width="370"
                height="630"
                src={link}
                onLoad={handleLoadSession}
                title="bureau"
              ></iframe>
            </Box>
          </Box>
        </Modal>
      </> */}
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <div className={styles.content}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <Label text="PAN number" left />
              <InputText
                square
                placeholder="PAN Number"
                changeHandler={(e) => setPan(e.target.value)}
                value={pan}
              />
              <br />

              <Label text="Date Of Birth" left />
              <InputText
                square
                placeholder="Date of Birth"
                changeHandler={(e) => {
                  console.log(e.target.value);
                  setDob(e.target.value);
                }}
                value={dob}
                type="date"
              />
              <br />
            </div>
            <div></div>
            <label
              style={{
                margin: "0 0.6rem",
                width: "100%",
                textAlign: "justify",
              }}
            >
              <input
                type="checkbox"
                name=""
                onChange={(e: any) => handleCheck(e.target.checked)}
                id=""
                style={{
                  marginRight: "0.4rem",
                  height: "1em",
                  width: "1em",
                }}
                checked={checked}
              />
              <span style={{ fontSize: "1em" }}>
                I consent and authorize <FeemonkText /> to get a background
                check and a consumer credit report on me.
              </span>
            </label>
          </div>
          <Button
            onPress={() => {
              getPanPro();
            }}
            imageRight={ArrowLeft}
            text={"Verify"}
            disabled={!checked || !pan || !dob}
            insureFin={userData?.channelId === 5}
          />
        </div>
        <br />
        <Footer />
      </div>
    </div>
  );
}

export default EnterPan;
