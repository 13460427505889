import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import IconIncomeDetails from "../../images/icons/income_details.svg";
import BankIcon from "../../images/static_assests/bank.svg";
import PdfIcon from "../../images/static_assests/pdf.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import LoanStepCard from "../../components/molecules/Card";
import InputText from "../../components/atoms/InputText";
import Button from "../../components/atoms/Button";
import Label from "../../components/atoms/Label";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { getApproval, updateEmployment } from "../../services/application";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";
import Dropzone, { useDropzone } from "react-dropzone";
import { BANK_LIST } from "../../utils/banks_list";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils/keys";
import { bankStatementPdfUpload } from "../../services/aa";
import { ApplicantStage } from "../../utils/applicationStage";
import { Modal, Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

function WorkDetails() {
  const authToken = sessionStorage.getItem("authToken") as string;
  const [newAuthToken, setNewAuthToken] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setError("");
  };
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        "application/pdf": [],
      },
      onDrop: (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
          setError("Only PDF files are allowed");
          setOpen(true);
        } else {
          setSuccessMessage("");
          setSelectedFile(acceptedFiles);
        }
      },
    });

  const location = useLocation();
  console.log("decode", location?.state?.data);

  const [employmentType, setEmploymentType] = useState<
    "salaried" | "self-employed"
  >("salaried");

  const [fipList, setFipList] = useState<
    {
      bank: String;
      health_up: Boolean;
      updated_at?: String;
      issue_details?: String | null;
    }[]
  >([]);

  const { userId, applicationId } = useContext(DataContext) as DataContextType;

  const [selectedBank, setSelectedBank] = useState("");
  const [searchText, setSearchText] = useState("");

  const [state, setState] = useState({
    employerName: "",
    salary: "",
    incomePerMonth: "",
    typeOfBusiness: "",
  });

  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const [bankDialog, setBankDialog] = useState(false);
  const [aaDialog, setAADialog] = useState(false);
  const [selectedMethod, setselectedMethod] = useState("");
  const [bankUplodDialog, setBankUploadDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File[] | null>(null);
  const [stage, setStage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${location?.state?.data?.applicationId}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setStage(res.data.skipStatement)
        if (res?.data?.stage) {
          switch (res?.data?.stage) {
            case ApplicantStage.PanVerification:
              navigate("/address", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.EmploymentDetails:
              setEmploymentDetailsSubmitted(true);
              break;
            case ApplicantStage.AddressDetails:
              navigate("/work-details", {
                state: { data: location?.state?.data },
              });
              // navigate("/address", { state: { data: location?.state?.data } });
              break;
            case ApplicantStage.BankStatement:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingApproval:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.CoapplicantRequired:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingCoapplicantConsent:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Approved:
              navigate("/select-emi", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.SanctionTermsAccepted:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Digilocker:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Selfie:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Agreement:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.BankAccountVerified:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Mandate:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.PFCollections:
              navigate("/pf-collection", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.ProcessComplete:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Disbursed:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Rejected:
              navigate("/rejected-page", {
                state: { data: location?.state?.data },
              });
              break;
            default:
              try {
                navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              } catch (err) {
                navigate(`/enter-pan`);
              }
              break;
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const submitUpload = async () => {
    const data = new FormData();
    if (selectedFile && selectedFile[0]) {
      data.append("file", selectedFile[0]);
    }

    data.append("userId", location?.state?.data?.userId);
    data.append("type", "pdf");
    data.append("applicationId", location?.state?.data?.applicationId);
    data.append("password", password);

    console.log(data);
    console.log(data.keys());

    console.log("userId", userId);
    console.log("applicationId", applicationId);

    const result = await bankStatementPdfUpload(data);
    console.log(result.message);
    if (result.message === "success") {
      navigate("/parking-page", {
        state: { data: location?.state?.data },
      });
    } else {
      setSuccessMessage("");
      setSelectedFile(null);
      setError(result.message);
    }
  };

  const [employmentDetailsSubmitted, setEmploymentDetailsSubmitted] =
    useState(false);

  useEffect(() => {
    const authenticate = () => {
      fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/account-aggregator/get-active-banks`
      )
        .then((response) => response.json())
        .then((result) => {
          if (result?.data) {
            setFipList(result?.data?.banks);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    authenticate();
  }, []);

  const getAARedirectLink = async () => {
    try {
      const authTokenResponse = await fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/login/auth`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // memberId: location?.state?.data?.userId,
            memberId: "26ae9a50-b0cd-4e7a-abc4-705edd5ae399",
          }),
        }
      );

      const authTokenResult = await authTokenResponse.json();

      console.log(authTokenResult);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${authTokenResult.data}`);

      const response = await fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/account-aggregator/finbox`,
        {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: JSON.stringify({
            bank: selectedBank,
            redirectUrl: `https://feemonk.com/parking-page`,
            // redirectUrl: `http://localhost:3002/parking-page`,
          }),
        }
      );

      const result = await response.json();

      console.log(result.data.url);

      window.open(result.data.url, "_self", "noopener,noreferrer");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    const getDetails = async () => {
      const response1 = await fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/login/auth`,
        {
          method: "POST",
          body: JSON.stringify({
            // memberId: location?.state?.data.userId,
            memberId: "26ae9a50-b0cd-4e7a-abc4-705edd5ae399",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result1 = await response1.json();
      const authToken = result1.data;
      setNewAuthToken(authToken);

      if (location?.state?.data?.userId) {
        const response2 = await fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/users/employment-details?userId=${location?.state?.data?.userId}&channelId=5`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const result2 = await response2.json();

        console.log(result2);

        setEmploymentType(result2?.data?.employmentType || "salaried");

        setState({
          employerName:
            result2?.data?.employerName === "string"
              ? ""
              : result2?.data?.employerName,
          salary: result2?.data?.salary === 0 ? "" : result2?.data?.salary,
          incomePerMonth:
            result2?.data?.incomePerMonth === 0
              ? ""
              : result2?.data?.incomePerMonth,
          typeOfBusiness:
            result2?.data?.typeOfBusiness === "string"
              ? ""
              : result2?.data?.typeOfBusiness,
        });
      } else {
        setState({
          employerName: "",
          salary: "",
          incomePerMonth: "",
          typeOfBusiness: "",
        });
      }
    };
    getDetails();
  }, []);

  const submitEmploymentDetails = () => {
    setLoading(true);
    // setPanDetails(PANzoopData);
    updateEmployment(
      {
        userId: location?.state?.data?.userId,
        applicationId: location?.state?.data?.applicationId,
        employmentType: employmentType,
        employerName: state?.employerName,
        salary: Number(state?.salary),
        incomePerMonth: Number(state?.incomePerMonth),
        typeOfBusiness: state?.typeOfBusiness,
        channelId: 5,
      },
      newAuthToken
    )
      .then((result) => {
        setLoading(false);
        setEmploymentDetailsSubmitted(true);
        // setLoading(true)
        // setTimeout(()=>{
        //   setLoading(false)
        // },2000)
        if (stage === true) {
          navigate("/parking-page", {
            state: { data: location?.state?.data },
          });
        }

        // console.log(result);
        // getApproval({
        //   applicationId,
        //   userId,
        // })
        //   .then((result) => {
        //     console.log(result);
        //     if (result.res.status === "Rejected") {
        // navigate("/congratulations");
        //       } else {
        //         navigate("/congratulations");
        //       }
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     });
        // })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={styles.body}>
      {error && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
          onClick={() => setError("")}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "2rem 5rem",
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(0,0,0,0.3)",
              margin: "0 1rem",
            }}
            onClick={() => setError("")}
          >
            <h1
              style={{
                fontFamily: "Outfit",
                textAlign: "center",
              }}
            >
              Error: {error}
            </h1>
          </div>
        </div>
      )}
      {bankUplodDialog && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: "#fff",
              padding: "1rem",
              borderRadius: "12px",
              width: "80%",
            }}
          >
            <div className={styles.bankContainer}>
              <p style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                Upload Bank Statement
              </p>

              <div
                {...getRootProps()}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  cursor: "pointer",
                  flex: 1,
                  background: "var(--box-background)",
                  border: "1px solid var(--primary-border-dark)",
                  borderRadius: "12px",
                }}
              >
                <input {...getInputProps()} />
                {selectedFile?.[0]?.name ? (
                  <>
                    {successMessage !== "" ? (
                      <p
                        style={{
                          color: "#2c6ce3",
                          fontSize: "1.5em",
                        }}
                      >
                        Uploading...
                      </p>
                    ) : (
                      <div>{selectedFile?.[0]?.name}</div>
                    )}
                  </>
                ) : (
                  <>
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 57 56"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="3.5"
                        y="3"
                        width="50"
                        height="50"
                        rx="25"
                        fill="#F2F4F7"
                      />
                      <rect
                        x="3.5"
                        y="3"
                        width="50"
                        height="50"
                        rx="25"
                        stroke="#F9FAFB"
                        stroke-width="6"
                      />
                      <path
                        d="M32.1586 32.0806L27.6721 27.594M27.6721 27.594L23.1855 32.0806M27.6721 27.594V37.6887M37.0826 34.7613C38.1765 34.1649 39.0408 33.2211 39.5388 32.079C40.0369 30.9369 40.1404 29.6614 39.8331 28.454C39.5257 27.2465 38.825 26.1757 37.8416 25.4107C36.8581 24.6457 35.6479 24.2299 34.4019 24.2291H
                          32.9886C32.6491 22.916 32.0163 21.6968 31.1379 20.6634C30.2594 19.63 29.1581 18.8092 27.9167 18.2627C26.6753 17.7162 25.3262 17.4582 23.9708 17.5082C22.6154 17.5581 21.2889 17.9147 20.0912 18.551C18.8934 19.1874 17.8554 20.087 17.0554 21.1823C16.2553 22.2775 15.714 23.5399 15.472 24.8745C15.2301 26.2091 15.2938 27.5812 15.6585 28.8876C16.0231 30.194 16.6792 31.4007 17.5774 32.417"
                        stroke="#475467"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <p
                      style={{
                        color: "#2c6ce3",
                        fontSize: "1em",
                      }}
                    >
                      Click to upload
                    </p>
                    <p
                      style={{
                        fontSize: "1em",
                      }}
                    >
                      PDF of Bank Statement
                    </p>
                  </>
                )}
              </div>

              {selectedFile?.[0]?.name && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <InputText
                    placeholder="Password of PDF (if any)"
                    type="password"
                    value={password}
                    changeHandler={(e) => setPassword(e.target.value)}
                    square
                  />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0.5rem 2rem",
                  alignItems: "center",
                }}
              >
                <Button
                  text={"Close"}
                  onPress={() => setBankUploadDialog((s) => !s)}
                  fullWidth={false}
                  noRightImage
                  secondary
                />
                <Button
                  text={"Next"}
                  onPress={() => {
                    setSuccessMessage("uploading");
                    // setBankUploadDialog((s) => !s);
                    submitUpload();
                    // navigate("/parking-page", {
                    //   state: { data: location?.state?.data },
                    // });
                  }}
                  fullWidth={false}
                  disabled={!selectedFile?.[0]?.name}
                  insureFin={location?.state?.data?.channelId === 5}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {bankDialog && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: "#fff",
              padding: "1rem",
              borderRadius: "12px",
              width: "80%",
            }}
          >
            <div className={styles.bankContainer}>
              <p style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                Select Bank
              </p>
              <InputText
                placeholder="Search banks"
                type="text"
                value={searchText}
                changeHandler={(e) => setSearchText(e.target.value)}
                style={{ width: "100%" }}
              />
              <div className={styles.bankList}>
                {BANK_LIST.filter((bank) =>
                  bank["Full name"]
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
                )
                  .sort(
                    (a, b) =>
                      a["Full name"]
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) -
                      b["Full name"]
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase())
                  )
                  .map((bank) => (
                    <div
                      className={styles.bank}
                      onClick={() => {
                        console.log();

                        setSelectedBank(bank["Name"]);
                      }}
                      style={{
                        backgroundColor:
                          selectedBank === bank["Name"] ? "#F5F5F5" : "",
                      }}
                      key={bank["Name"]}
                    >
                      <div
                        className={styles.bankImg}
                        style={{
                          backgroundImage: `url(${bank["Logo URL"]})`,
                        }}
                      ></div>
                      <p>{bank["Full name"]}</p>
                    </div>
                  ))}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0.5rem 2rem",
                  alignItems: "center",
                }}
              >
                <Button
                  text={"Close"}
                  onPress={() => setBankDialog((s) => !s)}
                  fullWidth={false}
                  noRightImage
                  secondary
                />
                <Button
                  text={"Next"}
                  insureFin={location?.state?.data?.channelId === 5}
                  // onPress={() => setBankDialog((s) => !s)}
                  onPress={() => {
                    const bank = BANK_LIST.find(
                      (bank) => bank["Name"] === selectedBank
                    );

                    setBankDialog((s) => !s);
                    setAADialog((s) => !s);

                    // if (
                    //   bank &&
                    //   fipList.find(
                    //     (a) =>
                    //       a?.bank.toLocaleUpperCase() ===
                    //       bank["Name"].toLocaleUpperCase()
                    //   )?.health_up
                    // ) {
                    //   // navigate(`/account-aggregator`, { state: { bank } });
                    // } else {
                    //   // navigate(`/pdf-upload-bank`);
                    // }
                  }}
                  fullWidth={false}
                  disabled={!selectedBank}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {aaDialog && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: "#fff",
              padding: "1rem",
              borderRadius: "12px",
              width: "80%",
            }}
          >
            <div className={styles.bankContainer}>
              <p
                style={{
                  fontSize: "1.2em",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Account Aggregator
              </p>
              {/* <p style={{ fontSize: "1em" }}>
                You will be redirected to the bank's website for account
                aggregation
              </p> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                  // alignItems: "center",
                  // justifyContent: "flex-start",
                  flex: 1,
                  paddingTop: "4rem",
                  flexShrink: 0,
                  // alignItems: "center",
                }}
              >
                {BANK_LIST.find((bank) => bank["Name"] === selectedBank) &&
                fipList.find(
                  (a) =>
                    a?.bank.toLocaleUpperCase() ===
                    BANK_LIST.find(
                      (bank) => bank["Name"] === selectedBank
                    )?.Name?.toLocaleUpperCase()
                )?.health_up ? (
                  <div
                    onClick={() => {
                      setselectedMethod("AA");
                    }}
                    style={{
                      // flex: 1,
                      borderRadius: "1rem",
                      border:
                        selectedMethod === "AA"
                          ? "2px solid #2c6ce3"
                          : "1px solid var(--primary-border)",
                      background: "var(--box-background-dark)",
                      boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)",
                      padding: "2rem 3rem",
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      style={{
                        height: "3rem",
                      }}
                      src={BankIcon}
                      alt=""
                    />
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: "500",
                        fontSize: "1.2rem",

                        textAlign: "justify",
                      }}
                    >
                      Securely Share Bank Statement by OTP sent through bank
                    </p>
                  </div>
                ) : null}
                <div
                  onClick={() => {
                    setselectedMethod("PDF");
                  }}
                  style={{
                    // flex: 1,
                    borderRadius: "1rem",
                    border:
                      selectedMethod === "PDF"
                        ? "2px solid #2c6ce3"
                        : "1px solid var(--primary-border)",
                    background: "var(--box-background-dark)",
                    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)",
                    padding: "2rem 3rem",
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{
                      height: "3rem",
                    }}
                    src={PdfIcon}
                    alt=""
                  />
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontWeight: "500",
                      fontSize: "1.2rem",
                      textAlign: "justify",
                    }}
                  >
                    Upload Bank Statement
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0.5rem 2rem",
                  alignItems: "center",
                }}
              >
                <Button
                  text={"Back"}
                  onPress={() => {
                    setAADialog((s) => !s);
                    setBankDialog((s) => !s);
                  }}
                  fullWidth={false}
                  noRightImage
                  secondary
                />
                <Button
                  text={"Next"}
                  insureFin={location?.state?.data?.channelId === 5}
                  onPress={() => {
                    if (selectedMethod === "AA") {
                      getAARedirectLink();
                    } else {
                      setAADialog((s) => !s);
                      setBankUploadDialog((s) => !s);
                    }
                    // if (
                    //   BANK_LIST.find((bank) => bank["Name"] === selectedBank) &&
                    //   fipList.find(
                    //     (a) =>
                    //       a?.bank.toLocaleUpperCase() ===
                    //       BANK_LIST.find(
                    //         (bank) => bank["Name"] === selectedBank
                    //       )?.Name?.toLocaleUpperCase()
                    //   )?.health_up
                    // ) {
                    //   setAADialog((s) => !s);
                    // } else {
                    //   setAADialog((s) => !s);
                    //   setBankUploadDialog((s) => !s);
                    // }
                    // // navigate(`/account-aggregator`);
                  }}
                  fullWidth={false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <LoanStepCard
            // description="Permanent Address & Current Location"
            title="Income details"
            image={IconIncomeDetails}
          />
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <div className={styles.content}>
              <div
                style={{
                  padding: "1rem",
                  background: "var(--box-background-dark)",
                  border: "1px solid var(--primary-border-dark)",
                  borderRadius: "12px 12px 0px 0px",
                  width: "100%",
                }}
              >
                <p style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                  Work Details
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  // alignItems: "center",
                  gap: "1rem",
                  border: "1px solid var(--primary-border-dark)",
                  background: "var(--box-background)",
                  padding: "1rem",
                  boxShadow: "0px 3px 3px var(--primary-border-shadow)",
                  borderRadius: "0px 0px 12px 12px",
                }}
              >
                <div className={styles.addressInputGrid}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        color: "#3D3D3D",
                        fontWeight: "bold",
                        fontSize: "1.2em",
                      }}
                    >
                      Employment details
                    </p>
                    <div
                      className={`${
                        employmentType === "salaried"
                          ? styles.employmentDetialsActive
                          : styles.employmentDetialsInactive
                      }`}
                      onClick={() => setEmploymentType("salaried")}
                    >
                      <p
                        style={{
                          fontSize: "1em",
                          padding: "0.5rem",
                          margin: 0,
                        }}
                      >
                        Salaried
                      </p>
                    </div>
                    <div
                      className={`${
                        employmentType === "self-employed"
                          ? styles.employmentDetialsActive
                          : styles.employmentDetialsInactive
                      }`}
                      onClick={() => setEmploymentType("self-employed")}
                    >
                      <p
                        style={{
                          fontSize: "1em",
                          padding: "0.5rem",
                          margin: 0,
                        }}
                      >
                        Self Employed
                      </p>
                    </div>
                  </div>

                  {employmentType === "salaried" ? (
                    <>
                      <div>
                        {/* <p style={{ fontSize: "1.2em" }}>Company Name</p> */}
                        <Label text="Company Name" />
                        <InputText
                          value={state.employerName}
                          changeHandler={(e) =>
                            setState((s) => ({
                              ...s,
                              employerName: e.target.value,
                            }))
                          }
                          square
                          placeholder="Ex: FeeMonk"
                        />
                      </div>
                      <div>
                        <Label text="Net Monthly Salary" />
                        {/* <p style={{ fontSize: "1.2em" }}>Net Annual Salary</p> */}
                        <InputText
                          value={state.salary}
                          changeHandler={(e) =>
                            setState((s) => ({ ...s, salary: e.target.value }))
                          }
                          square
                          placeholder="₹"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <Label text="Profession" />
                        {/* <p style={{ fontSize: "1.2em" }}>Profession</p> */}
                        <InputText
                          value={state.typeOfBusiness}
                          changeHandler={(e) =>
                            setState((s) => ({
                              ...s,
                              typeOfBusiness: e.target.value,
                            }))
                          }
                          square
                          placeholder="Profession"
                        />
                      </div>
                      <div>
                        <Label text="Monthly income" />
                        {/* <p style={{ fontSize: "1.2em" }}>Yearly income</p> */}
                        <InputText
                          value={state.incomePerMonth}
                          changeHandler={(e) =>
                            setState((s) => ({
                              ...s,
                              incomePerMonth: e.target.value,
                            }))
                          }
                          square
                          placeholder="₹"
                        />
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      insureFin={location?.state?.data?.channelId === 5}
                      onPress={() => {
                        submitEmploymentDetails();
                      }}
                      text={"Save"}
                      noRightImage
                      disabled={
                        (employmentType === "salaried"
                          ? !state.employerName || !state.salary
                          : !state.incomePerMonth || !state.typeOfBusiness) ||
                        employmentDetailsSubmitted
                      }
                    />
                  </div>
                </div>
                <br />
              </div>
            </div>
          )}
          {stage === false ? (
            <div className={styles.content}>
              <div
                style={{
                  padding: "1rem",
                  background: "var(--box-background-dark)",
                  border: "1px solid var(--primary-border-dark)",
                  borderRadius: "12px 12px 0px 0px",
                  width: "100%",
                }}
              >
                <p style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                  Bank Statement
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  // alignItems: "center",
                  gap: "1rem",
                  border: "1px solid var(--primary-border-dark)",
                  background: "var(--box-background)",
                  padding: "1rem",
                  boxShadow: "0px 3px 3px var(--primary-border-shadow)",
                  borderRadius: "0px 0px 12px 12px",
                  width: "100%",
                }}
              >
                <p style={{ fontSize: "1.2em", textAlign: "justify" }}>
                  Uploading a bank account statement can enhance your chances of
                  availing better loan amount
                </p>
                <section
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    insureFin={location?.state?.data?.channelId === 5}
                    // insureFin
                    text={"Select Bank"}
                    onPress={() => {
                      setBankDialog((s) => !s);
                      // submitEmploymentDetails();
                    }}
                    noRightImage
                    disabled={!employmentDetailsSubmitted}
                  />
                  {/* <div
             {...getRootProps()}
             style={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               flexDirection: "column",
               cursor: "pointer",
             }}
           >
             <input {...getInputProps()} />
             <svg
               width="50"
               height="50"
               viewBox="0 0 57 56"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <rect
                 x="3.5"
                 y="3"
                 width="50"
                 height="50"
                 rx="25"
                 fill="#F2F4F7"
               />
               <rect
                 x="3.5"
                 y="3"
                 width="50"
                 height="50"
                 rx="25"
                 stroke="#F9FAFB"
                 stroke-width="6"
               />
               <path
                 d="M32.1586 32.0806L27.6721 27.594M27.6721 27.594L23.1855 32.0806M27.6721 27.594V37.6887M37.0826 34.7613C38.1765 34.1649 39.0408 33.2211 39.5388 32.079C40.0369 30.9369 40.1404 29.6614 39.8331 28.454C39.5257 27.2465 38.825 26.1757 37.8416 25.4107C36.8581 24.6457 35.6479 24.2299 34.4019 24.2291H32.9886C32.6491 22.916 32.0163 21.6968 31.1379 20.6634C30.2594 19.63 29.1581 18.8092 27.9167 18.2627C26.6753 17.7162 25.3262 17.4582 23.9708 17.5082C22.6154 17.5581 21.2889 17.9147 20.0912 18.551C18.8934 19.1874 17.8554 20.087 17.0554 21.1823C16.2553 22.2775 15.714 23.5399 15.472 24.8745C15.2301 26.2091 15.2938 27.5812 15.6585 28.8876C16.0231 30.194 16.6792 31.4007 17.5774 32.417"
                 stroke="#475467"
                 stroke-width="1.66667"
                 stroke-linecap="round"
                 stroke-linejoin="round"
               />
             </svg>

             <p
               style={{
                 color: "#d32028",
                 fontSize: "1em",
               }}
             >
               Click to upload
             </p>
             <p
               style={{
                 fontSize: "1em",
               }}
             >
               PDF or ZIP
             </p>
           </div> */}
                </section>
              </div>
            </div>
          ) : null}

          {/* <Button
            text={"Save & Next"}
            onPress={() => {
              submitEmploymentDetails();
            }}
            disabled={true}
          /> */}
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default WorkDetails;
