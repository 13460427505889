export const ApplicantStage = {
  PanVerification: 1,
  AddressDetails: 2,
  EmploymentDetails: 3,
  BankStatement: 4,
  AwaitingApproval: 5,
  CoapplicantRequired: 6,
  AwaitingCoapplicantConsent: 7,
  Approved: 8,
  SanctionTermsAccepted: 9,
  Digilocker: 10,
  Selfie: 11,
  Agreement: 12,
  BankAccountVerified: 13,
  Mandate: 14,
  PFCollections: 15,
  ProcessComplete: 16,
  Disbursed: 17,
  Rejected: 18,
};
